import { Injectable, OnInit } from '@angular/core';
import { Trash } from '../model/Trash';
import { Storage } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})

export class TrashReportService {

  private key = 'trash';
  private nextId: number;
  public markers = [];

  constructor(
    private storage: Storage,
    private geolocation: Geolocation,
    private firestore: AngularFirestore,
    
    ) {
    this.storage.get(this.key).then((trashes) => {
      if (!trashes) {
        this.nextId = 0;
      } else {
        this.nextId = trashes.length;
      }
    });
  }

 





  async setTrash(FileURI: string): Promise<void> {
    console.log('setTrash ', FileURI);
    const newTrash: Trash = { 
        id: 100,
        image: "",
        reportedBy: "Timo",
        reportedDate: "12.12.1991",
        reportedTime: "23:59:12",
        comment: "geht ab",
        trashSize: "1",
        lat: 0,
        lng: 0
    };
    newTrash.id = this.nextId;
    newTrash.image = FileURI;


    const position = await this.geolocation.getCurrentPosition();

    newTrash.lat = position.coords.latitude;
    newTrash.lng = position.coords.longitude;

    const oldTrashes = await this.storage.get(this.key);

    if (!oldTrashes) {
      try {
        await this.storage.set(this.key, [newTrash]);
        console.log('first setTrash success', newTrash.id)
      } catch {
        console.log('first setTrash error', newTrash.id);
      }
    } else {
      oldTrashes.push(newTrash);
      try {
        await this.storage.set(this.key, oldTrashes);
        console.log('setTrash success', newTrash.id);
      } catch {
        console.log('setTrash error', newTrash.id);
      }
    }

    // tslint:disable-next-line: arrow-return-shorthand
    this.storage.get(this.key).then(x => { console.log(x); });

    this.nextId++;
  }
 


  async DBTrash(): Promise<void> {
    let userDoc = this.firestore.collection("trashletics");
    userDoc.get().toPromise().then((querySnapshot) => {
      querySnapshot.docs.forEach(doc => {
        this.markers.push(doc.data());
      });
       for(let index = 0; index < querySnapshot.docs.length; index++){
        console.log(this.markers);
        console.log(this.markers[0]);
        console.log(this.markers[1]);
       Garbage.push({
        id: this.markers[index].ID, 
        image: this.markers[index].image,
        reportedBy: this.markers[index].reportedBy,
        reportedDate: this.markers[index].reportedDate,
        reportedTime: this.markers[index].reportedTime,
        comment: this.markers[index].comment,
        trashSize: this.markers[index].trashSize,

        lat: this.markers[index].Geo.latitude,
        lng: this.markers[index].Geo.longitude
      });
    
    } 
      console.log(Garbage);
      console.log(this.markers[0]);
      this.storage.set(this.key, Garbage);
       })
    

    const Garbage = [
      {
        id: 0,
        image: '../../assets/icon/placeholder_trash.jpg',
        reportedBy: "Timo",
        reportedDate: "28.11.2019",
        reportedTime: "23:59:12",
        comment: "leicht aufzusammeln",
        lat: 53.555309, 
        lng: 10.016256,
        trashSize: "1"
      },
      {
        image: '../../assets/icon/placeholder_trash.jpg',
        id: 1,
        reportedBy: "Olga",
        reportedDate: "27.11.2019",
        reportedTime: "23:59:12",
        comment: "stark verschmutzt",
        lat: 53.548885,
        lng: 10.019549,
        trashSize: "1"
      }
    ];  
  
  }
  

  getTrash(id: number): Promise<Trash> {
    return this.storage.get(this.key).then((trashes) => {

      return trashes.find((el) => {
        return id === el.id;
      });

    }).catch((error) => {

      console.log('getTrash error', id);

    });
  }
  getAllTrash(): Promise<Trash[]> {
    return this.storage.get(this.key).then((trashes) => {
      return trashes;
    });

  }
}
