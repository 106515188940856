// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    
      apiKey: "AIzaSyCDyd_oZO8kdYPLclU_6sMtcZ2cnWEfhxw",
      authDomain: "trashletics.firebaseapp.com",
      databaseURL: "https://trashletics.firebaseio.com",
      projectId: "trashletics",
      storageBucket: "trashletics.appspot.com",
      messagingSenderId: "715372201072",
      appId: "1:715372201072:web:4d7bf6e26a7814e37160b5"
    
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
